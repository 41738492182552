import { CommonModule, TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FolderService } from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';
import { FolderTreeComponent } from '../folder/folder-tree/folder-tree.component';
import { FormDialogComponent } from './form-dialog.component';
import { AngularSplitModule } from 'angular-split';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Media } from '@designage/gql';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    FormDialogComponent,
    FolderTreeComponent,
    AngularSplitModule,
    TitleCasePipe,
    NgbTooltip,
  ],
  selector: 'move-media-folder-dialog',
  template: `<app-form-dialog
    headerText="MOVE_MEDIA"
    dismissButtonText="CANCEL"
    closeButtonText="MOVE"
    [values]="selectedFolderId"
    [valid]="selectedFolderId !== undefined"
  >
    <div class="p-3">
      {{ 'MOVE_MEDIA_INFO' | translate }}
    </div>
    @if (selectedMedia.length === 1) {
      <h4 class="p-3">
        {{
          selectedMedia[0].name +
            ' (' +
            (selectedMedia[0].type | titlecase) +
            ')'
        }}
      </h4>
    }
    <as-split direction="horizontal" style="height: 40vh">
      <as-split-area class="card m-1" [visible]="selectedMedia.length > 1">
        <h5 class="card-header">{{ 'MEDIA_FILES' | translate }}</h5>
        <div class="card-body overflow-auto pt-3">
          @for (media of selectedMedia; track media.id) {
            <div class="vstack">
              <div class="hstack gap-3 justify-content-between">
                <span class="text-truncate fs-6" [ngbTooltip]="media.name">
                  {{ media.name }}
                </span>
                <span class="mx-4"> {{ media.type | titlecase }} </span>
              </div>
              <hr />
            </div>
          }
        </div>
      </as-split-area>
      <as-split-area class="card m-1">
        <h5 class="card-header">
          {{ 'TARGET_FOLDER' | translate }}
        </h5>
        <div class="card-body overflow-auto">
          <app-folder-tree
            [showNodeMenu]="false"
            [showTrashFolderTree]="false"
            (selectFolderId)="selectedFolderId = $event"
          >
          </app-folder-tree>
        </div>
      </as-split-area>
    </as-split>
  </app-form-dialog> `,
  styles: [
    `
      h5 {
        text-align: center;
      }
    `,
  ],
  providers: [FolderService],
})
export class MoveMediaFolderDialogComponent implements OnInit {
  selectedFolderId: string | null = null;
  selectedMedia: Media[] = [];
  // MoveType: ''

  constructor() {}

  ngOnInit(): void {
    console.log('selectedMedia', this.selectedMedia);
  }
}
